// tslint:disable: max-line-length
export const environment = {
    production: false,
    partnerForgotPasswordURL: 'https://na97.salesforce.com/secur/forgotpasswordp.jsp?orgId=00D400000009hQR&portalId=06040000000D6Nb&retURL=%2Fsecur%2Flogin_portal.jsp',
    myVMForgotPasswordURL: 'https://my.vmware.com/web/vmware/forgot-password',
    signUpURL: 'https://my.vmware.com/web/vmware/registration',
    ssoURL: '',
    apiUrl: 'https://auth.esp-staging.vmware-aws.com/api/auth/v1',
    inputServiceUrl: 'https://vcfcapacityplanner.esp-staging.eng.vmware.com/input',
    rvToolUrl: 'https://vcfcapacityplanner.esp-staging.eng.vmware.com/input',
    recommendationServiceUrl: 'https://vcfcapacityplanner.esp-staging.eng.vmware.com/recomm',
    sfdcUrl : 'https://customer-metadata.esp-dev.vmware-aws.com',
    CLIENT_ID: 'esp-decc-stg-430-oekwi',
    environmentType: 'dev',
    trackingId: '',
    featureModule: 'saved-configurations',
    orgId : 'cd5f8911-03fc-4cc0-b4e0-a532af2c6c08',
    codeChallenge: '7GqmC3rOWGkyEDBlxSVh6xsLi0LZcpnMgtRps_B0Mw8',
    codeChallengeMethod:'S256',
    codeVerifier:'u1adRVd1-dUNkDWcjPF07VOedFXJSX9vH6wu3NO8fP2sADqDcOUM-8Tp_WiT73iUE9m3o8bxYQn2SDp0B_4ZZOj3nY5ctpwWrdlJZeBsvJSsXfm1aOhugnh2v69bbmeV',
    authApiUrl: 'https://console-stg.cloud.vmware.com',
    STATE: 'CXNwLWFwaS1jbGllbnQ=',
};
