import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { HelpComponent } from './components/help/help.component';
import { AccountInfoComponent } from './components/account-info/account-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { LoginComponent } from './components/login/login.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { onAppInit } from './app.initializer';
import { EnvironmentsService } from './services/environments.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthCallbackComponent } from './components/auth/auth-callback/auth-callback.component';
import { LogoutComponent } from './components/logout/logout.component';
//import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';


@NgModule({
  declarations: [
    AppComponent,
    HelpComponent,
    AccountInfoComponent,
    // LoginComponent,
    AppHeaderComponent,
    //ReleaseNotesComponent,
    AuthCallbackComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsModule.forRoot([
      
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule
  ],
  providers: [EnvironmentsService,BnNgIdleService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      multi: true,
      deps: [Injector],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
